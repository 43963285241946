<template>
  <div class="com-home-QuestionFive">
    <img
      class="img0"
      src="../../../../public/img/QuestionSolution/QuestionFive/底纹.png"
    />
    <img
      class="img1"
      src="../../../../public/img/QuestionSolution/QuestionFive/主文案@2x.png"
    />
     <img
      class="img2"
      src="../../../../public/img/QuestionSolution/QuestionFive/点缀.png"
    />
    <img
      class="img3"
      src="../../../../public/img/QuestionSolution/QuestionFive/电脑.png"
    />
    <img
      class="img4"
      src="../../../../public/img/QuestionSolution/QuestionFive/悬浮一@2x.png"
    />
    <img
      class="img5"
      src="../../../../public/img/QuestionSolution/QuestionFive/悬浮 文字@2x.png"
    />
   
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.$el.style.visibility = "visible";
      this.api.animateCSS(this.$el.querySelector(".img1"), "fadeInUp");
      this.api.animateCSS(this.$el.querySelector(".img3"), "fadeInUp")
      setTimeout(() => {
        const img4 = this.$el.querySelector(".img4");
        img4.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img4"), "zoomIn");
      }, 600);
      setTimeout(() => {
        const img5 = this.$el.querySelector(".img5");
        img5.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img5"), "fadeInUp");
      }, 800);
      setTimeout(() => {
        const img2 = this.$el.querySelector(".img2");
        img2.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img2"), "fadeIn");
      }, 1500);
    }, 100);
  },
};
</script>
<style lang="less" scoped>
.com-home-QuestionFive {   
  .img0 {
    margin-left: 100px;
    margin-top: 100px;
  }
  .img1 {
    width: 560px;
    margin-left: 160px;
    margin-top: 10px;
  }
  .img2 {
    display: none;
    margin-left: 120px;
    margin-top: 135px;
  }
  .img3{
    margin-left: 165px;
    margin-top: 145px;
  }
  .img4{
    width: 666px;
    display: none;
    margin-left: 40px;
    margin-top: 135px;
  }
  .img5{
    width: 332px;
    display: none;
    margin-left: 455px;
    margin-top: 350px;
  }
}
</style>